import { template as template_06b0a79af164417da710e6974756bb18 } from "@ember/template-compiler";
import IdentifiedLearner from './identified-learner';
export default template_06b0a79af164417da710e6974756bb18(`
  <div class="header">
    {{yield}}
    <IdentifiedLearner />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
