import { template as template_29336c96db4b43318b32111d2b2c1993 } from "@ember/template-compiler";
export default template_29336c96db4b43318b32111d2b2c1993(`
  <div class="width-limited-content">
    <div class="width-limited-content__inner-content">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
