import { template as template_c3cd15a489f2425f920a8c8dff094a87 } from "@ember/template-compiler";
export default template_c3cd15a489f2425f920a8c8dff094a87(`
  <div class="card-wrapper">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
