import { template as template_6e7ae4fc1f944b19a9e2439d48e9f537 } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class IdentifiedLearner extends Component {
    @service
    router;
    @service
    currentLearner;
    get currentUserDisplayName() {
        return this.currentLearner.learner?.displayName;
    }
    @action
    disconnect() {
        this.router.transitionTo(this.currentLearner.learner?.schoolUrl);
    }
    static{
        template_6e7ae4fc1f944b19a9e2439d48e9f537(`
    <div class="identified-learner">
      <div class="identified-learner__informations">
        <p>{{this.currentUserDisplayName}}</p>
        <PixButton @variant="tertiary" @iconBefore="logout" @triggerAction={{this.disconnect}}>
          {{t "components.login.logout-button"}}
        </PixButton>
      </div>
      <PixIcon class="identified-learner__icon" @name="userCircle" />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
