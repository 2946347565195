import { template as template_c1c071a7bcc947ed9a3a91157936c1c3 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import WidthLimitedContent from '../width-limited-content';
export default class ChallengeLayout extends Component {
    get className() {
        return this.args.color ?? 'default';
    }
    static{
        template_c1c071a7bcc947ed9a3a91157936c1c3(`
    <div class="challenge-layout challenge-layout--{{this.className}}">
      <WidthLimitedContent>{{yield}}</WidthLimitedContent>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
