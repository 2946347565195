import { template as template_23c29cec93f04b68a2bf9d6f25dcf895 } from "@ember/template-compiler";
import Background from './mission-card-completed-background';
export default template_23c29cec93f04b68a2bf9d6f25dcf895(`
  <div class="mission-card__container">
    {{#if @missionLabelStatus}}
      <div class="status status--completed">
        <p>{{@missionLabelStatus}}</p>
      </div>
    {{/if}}

    <div class="mission-icon--completed">
      <img src="/images/mission/icon/pix-junior-logo-white.svg" alt="" aria-hidden="true" />
    </div>
    <Background @areaCode={{@areaCode}} />
    <div class="completed-mission-card-bottom">
      <p>{{@title}}</p>
    </div>

  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
