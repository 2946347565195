import { template as template_dadc22d11ce74ca9880c0a1417fd8e81 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
export default class DelayedElement extends Component {
    @tracked
    display = false;
    constructor(){
        super(...arguments);
        if (this.args.shouldDisplayIn > 0) {
            setTimeout(()=>{
                this.display = true;
            }, this.args.shouldDisplayIn);
        } else {
            this.display = true;
        }
    }
    static{
        template_dadc22d11ce74ca9880c0a1417fd8e81(`
    <section class="element-delayed-wrapper {{if this.display 'display' ''}}">
      {{#if this.display}}
        {{yield}}
      {{/if}}
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
